import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { BsFillStarFill } from 'react-icons/bs';

import { uniqueId } from 'lodash';
import EmptyHouse from '../../assets/EmptyHouse.svg';

import { formatNumberToPrice } from '../../utils/formatNumber';

import {
  PartyHouseCard,
  PartyHouseCardInfos,
  PartyHouseCardImage,
  PartyHouseCardPrice,
  PartyHouseCardFooter,
  PartyHouseRating,
} from './styles';
import { House } from '../../types/house';

interface PartyHouseLargeCard {
  visible: boolean;
  item: House;
}

const PartyHouseLargeCard = ({ visible, item }: PartyHouseLargeCard): ReactElement => (
  <PartyHouseCard visible={visible}>
    <Link to={{ pathname: `${item?.slug}`, state: { houseId: item?.id, cityId: item?.address?.city?.id } }}>
      <PartyHouseCardImage src={item?.principalPhotoUrl || EmptyHouse} />
      <PartyHouseCardInfos>
        <h3>{item?.name || ''}</h3>
        <small>{`${item?.address?.street} ${item?.address?.number}, ${item?.address?.neighborhood} - ${item?.address?.city?.name}` || ''}</small>
        <p>
          {item?.description || ''}
        </p>
        <PartyHouseCardFooter>
          <PartyHouseRating>
            {[...Array(Math.trunc(item?.rating?.average) || 0)].map(() => <BsFillStarFill key={uniqueId()} />)}
            {item?.rating?.average || '--'}
            <span>
              -
              {' '}
              {item?.rating?.total || '0'}
              {' '}
              avaliações
            </span>
          </PartyHouseRating>
          <PartyHouseCardPrice>
            A partir de:
            <b>
              <em>{formatNumberToPrice(item?.lowestValueAvailable) || '00'}</em>
            </b>
          </PartyHouseCardPrice>
        </PartyHouseCardFooter>
      </PartyHouseCardInfos>
    </Link>
  </PartyHouseCard>
);

export default PartyHouseLargeCard;
