// import _ from 'lodash';
import qs from 'qs';
import { Entity } from '../types/strapi';
import api from '../clients/api.client';

export default {
  async getOne(id: string | number): Promise<Entity<any>> {
    try {
      return (
        await api.get(`/plan/price/${id}`, {})
      ).data;
    } catch {
      throw new Error('Unable to list the plan!');
    }
  },

  async get(filter = {} as any, houseId: string, cityId: string): Promise<Entity<any>> {
    try {
      return (
        await api.get('/plan', {
          paramsSerializer: (params) => qs.stringify(params),
          params: {
            houseId,
            type: filter?.type?.value,
            date: filter?.date,
            guests: filter?.guests,
            shift: filter?.shift?.value,
            cityId,
          },
        })
      ).data;
    } catch {
      throw new Error('Unable to list the plans!');
    }
  },

  async getMenuItens(id: string | number): Promise<Entity<any>> {
    try {
      return (
        await api.get(`/menus/${id}`, {})
      ).data;
    } catch {
      throw new Error('Unable to list the plan!');
    }
  },
};
