import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';

import { RiTimeLine, RiCloseLine, RiStarFill } from 'react-icons/ri';

import { uniqueId } from 'lodash';
import EmptyHouse from '../../assets/EmptyHouse.svg';

import { plansService } from '../../services';

import {
  Container,
  PlanModalContent,
  PlanModalContentHeader,
  PartyHouseImage,
  PlanModalContentHeaderTexts,
  PartyHouseMainContent,
  PartyHouseMainContentTexts,
  PartyHouseRating,
  SelectedFiltersList,
  EventDuration,
  FinalValue,
  PartyHouseObservations,
  DetailItems,
  Accommodations,
  MenuItems,
  MenuItemsList,
  MenuItem,
  AccommodationsList,
  AccommodationItem,
  FilterItem,
} from './styles';
import { RatingStars } from '../PreBookingModal/styles';

interface PlanModalProps {
  handleClose: () => void;
  toggleAllItemsModal: () => void;
  toggleMenuItemsModal: () => void;
  togglePreBookingModal: () => void;
  selectedPackage: string | number;
  selectedPlanValue: string | number;
  facilities: any[],
  houseInfos: any,
}

const PlanModal = ({
  handleClose,
  toggleAllItemsModal,
  toggleMenuItemsModal,
  togglePreBookingModal,
  selectedPackage,
  selectedPlanValue,
  facilities,
  houseInfos,
}: PlanModalProps): ReactElement => {
  const [packageInformations, setPackageInformations] = useState<any>([]);
  const [menuItens, setMenuItens] = useState<any>([]);
  const [storagedFilters, setStoragedFilters] = useState<any>({});

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    getPlanInformations().then((planPrice: any) => getMenuInformations(planPrice?.plan));

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  async function getPlanInformations(): Promise<any> {
    const parsedFilters = JSON.parse(sessionStorage.getItem('@App:filters') as any);
    setStoragedFilters(parsedFilters || []);

    const packageInformationsResponse = (await plansService.getOne(selectedPackage));
    setPackageInformations(packageInformationsResponse);

    return packageInformationsResponse;
  }

  async function getMenuInformations(plan: any): Promise<void> {
    const menuItensResponse = (await plansService.getMenuItens(plan.id));
    setMenuItens(menuItensResponse);
  }

  return (
    <Container>
      <PlanModalContent>
        <PlanModalContentHeader>
          <PartyHouseImage src={houseInfos?.principalPhotoUrl || EmptyHouse} alt="EmptyHouse" />
          <PlanModalContentHeaderTexts>
            <h2>{packageInformations?.plan?.name ?? ''}</h2>
            <p>
              {packageInformations?.plan?.description ?? ''}
            </p>
          </PlanModalContentHeaderTexts>
          <button type="button" onClick={handleClose}>
            <RiCloseLine />
          </button>
        </PlanModalContentHeader>

        <PartyHouseMainContent>
          <PartyHouseMainContentTexts>
            <h3>
              {houseInfos?.name ?? ''}
              <small>
                <b>
                  {houseInfos?.address ? `${houseInfos?.address?.street}, ${houseInfos?.address?.number}, ${houseInfos?.address?.neighborhood} - ${houseInfos?.address?.city?.name}` : ''}
                </b>
              </small>
            </h3>

            <PartyHouseRating>
              <RatingStars>
                {houseInfos?.rating?.average || '--'}
                {[...Array(Math.trunc(houseInfos?.rating?.average) || 0)].map(() => <RiStarFill key={uniqueId()} />)}
              </RatingStars>
              <small>
                {houseInfos?.rating?.total || '0'}
                {' '}
                Avaliações
              </small>
            </PartyHouseRating>
          </PartyHouseMainContentTexts>

          <SelectedFiltersList>
            <FilterItem>
              <small>Data da festa</small>
              {moment(storagedFilters?.date).format('DD/MM/YYYY') ?? ''}
            </FilterItem>

            <FilterItem>
              <small>Nº de convidados</small>
              {storagedFilters?.guests ?? '50'}
            </FilterItem>

            <FilterItem>
              <small>Turno</small>
              {storagedFilters?.shift?.name ?? 'Tarde'}
            </FilterItem>
          </SelectedFiltersList>

          <DetailItems>
            <Accommodations>
              <h4>O que terá na sua festa</h4>
              <AccommodationsList>
                {facilities
                  .slice(0, 4)
                  .map((facility: { id: string; icon: string; name: string; }) => (
                    <AccommodationItem key={facility.id}>
                      {facility.name}
                    </AccommodationItem>
                  ))}
              </AccommodationsList>
              <button type="button" onClick={toggleAllItemsModal}>Mostrar todos os itens</button>
            </Accommodations>

            <MenuItems>
              <h4>Cardápio</h4>
              <MenuItemsList>
                {menuItens
                  .slice(0, 6)
                  .map((menuItem: { id: string; icon: string; name: string; }) => (
                    <MenuItem key={menuItem.id}>
                      {menuItem.name}
                    </MenuItem>
                  ))}
              </MenuItemsList>

              <button type="button" onClick={toggleMenuItemsModal} disabled={!menuItens}>Mostrar cardápio completo</button>
            </MenuItems>
          </DetailItems>

          <EventDuration>
            <RiTimeLine />
            Evento com duração de:
            {' '}
            <b>{packageInformations?.plan?.duration ?? ''}</b>
          </EventDuration>

          <FinalValue>
            <span>
              Valor final:
              <b>{selectedPlanValue}</b>
            </span>

            <p>
              {houseInfos?.infoBooking
                ?? 'Ao realizar sua pré-reserva, você terá até 3 dias úteis para realizar a entrada e confirmar sua reserva de forma definitiva.'}
            </p>
          </FinalValue>

          <button type="button" onClick={togglePreBookingModal}>Realizar minha pré-reserva!</button>
        </PartyHouseMainContent>

        <PartyHouseObservations>
          <h5>Observações</h5>
          <p>
            {packageInformations?.plan?.note ?? ''}
          </p>
        </PartyHouseObservations>
      </PlanModalContent>
    </Container>
  );
};

export default PlanModal;
