import React, {
  useEffect,
  useState,
} from 'react';
import {
  RiArrowLeftSLine,
  RiCarLine,
  RiGroupLine,
  RiInformationLine,
  RiMagicFill,
  RiMergeCellsHorizontal,
  RiSearchLine,
  RiStarFill,
  RiUserSmileLine,
} from 'react-icons/ri';
import { Link, useLocation, useParams } from 'react-router-dom';

import { uniqueId } from 'lodash';
import { getMonthName } from '../../utils/calendarFormatters';

import AllItemsModal from '../../components/AllItemsModal';
import Footer from '../../components/Footer';
import MenuItemsModal from '../../components/MenuItemsModal';
import PlanModal from '../../components/PlanModal';
import PreBookingConfirmationModal from '../../components/PreBookingConfirmationModal';
import PreBookingModal from '../../components/PreBookingModal';

import EmptyHouse from '../../assets/EmptyHouse.svg';

import {
  facilitiesService,
  filtersService,
  housesService,
  plansService,
} from '../../services';

import { HouseSearchFilter } from '../../types/house.search.filter';
import { getFacilityIcon } from '../../utils/facilityIcons';
import { formatNumberToPrice } from '../../utils/formatNumber';
import {
  AboutPartyHouse,
  AboutPartyHouseHeader,
  AboutPartyHousePhotos,
  AboutPartyHouseTitles,
  AvailableDate,
  ButtonPreBooking,
  Container,
  // PaymentConditions,
  // PaymentSectionBox,
  // PaymentMethodsRow,
  // PaymentIconBox,
  EmptyBox,
  FacilitiesItem,
  FacilitiesList,
  LeftContent,
  MainPhoto,
  PackageDescriptionContent,
  PackageItem,
  PackageItemContent,
  PackageItemPrice,
  PackageItemRadio,
  PackageItemTexts,
  PackagesList,
  PartyHouseCapacity,
  PartyHouseCapacityItem,
  PartyHouseCapacityTexts,
  PartyHouseDescription,
  PartyHouseFacilities,
  PartyHouseRating,
  PhotoGridItem,
  PhotoGridRow,
  PhotosGrid,
  PreBookingButton,
  PreBookingWarning,
  PriceAndConditionFilter,
  PricesAndConditions,
  PricesAndConditionsFilters,
  RatingStars,
  ReservationDetailsHeader,
  WarningTooltip,
} from './styles';

interface UrlParams {
  slug: string;
}

interface StateParams {
  houseId: string;
  cityId: string;
}

const HouseDetail: React.FC = () => {
  const { slug: partyHouseSlug } = useParams() as UrlParams;
  const { state: { houseId, cityId } } = useLocation<StateParams>();
  const [activePackage, setActivePackage] = useState<number | any>(null);
  const [selectedPlanValue, setSelectedPlanValue] = useState<string>('');
  const [visibleAllItemsModal, setVisibleAllItemsModal] = useState<boolean>(false);
  const [visibleMenuItemsModal, setVisibleMenuItemsModal] = useState<boolean>(false);
  const [visiblePreBookingModal, setVisiblePreBookingModal] = useState<boolean>(false);
  const [visiblePreBookingConfirmationModal, setVisiblePreBookingConfirmationModal] = useState<boolean>(false);
  const [partyHouseInfos, setPartyHouseInfos] = useState([] as any);
  const [facilities, setFacilities] = useState([] as any);
  const [filtersList, setFiltersList] = useState<any>([]);
  const [plansList, setPlansList] = useState<any>([]);
  const [storagedFilters, setStoragedFilters] = useState<any>({});
  const selectedDate = storagedFilters?.date ? new Date(storagedFilters?.date) : new Date();

  const [filterValues, setFilterValues] = useState({} as HouseSearchFilter);

  useEffect(() => {
    window.scrollTo(0, 0);
    getHouseDetails();
    getFilters();
    getFacilities();
    getPlans();
    getFilterValues();
  }, []);

  async function getFilterValues(): Promise<void> {
    const appFilter = sessionStorage.getItem('@App:filters');
    if (appFilter) {
      const appFilterObject = JSON.parse(appFilter) as any;
      setFilterValues({
        type: appFilterObject?.type?.value,
        date: appFilterObject.date,
        guests: appFilterObject.guests,
        shift: appFilterObject?.shift?.value,
      });
    }
  }

  async function getHouseDetails(): Promise<void> {
    setPartyHouseInfos(await housesService.getBySlug(partyHouseSlug));
  }

  async function getFacilities(): Promise<void> {
    setFacilities(await facilitiesService.findByHouseId(houseId));
  }

  function scrollToRef(ref: any): any {
    if (ref) {
      document.getElementById(ref)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  const getFilters = async (): Promise<void> => {
    setFiltersList((await filtersService.get()) || []);
  };

  const getPlans = async (): Promise<void> => {
    const parsedFilters = sessionStorage.getItem('@App:filters') ? JSON.parse(sessionStorage.getItem('@App:filters') as any) : {};
    setStoragedFilters(parsedFilters || []);

    setPlansList(await plansService.get(parsedFilters, houseId, cityId));
  };

  function handleInputChange(event: any): void {
    const { name, value } = event?.target;

    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  }

  function handleFilterChange(): any {
    if (filterValues.date && filterValues.guests && filterValues.shift && filterValues.type) {
      const filter = structuredClone(filterValues);

      filter.shift = filterValues?.shift ? filtersList?.shifts.filter((s: { value: string }) => s.value === filterValues.shift)[0] : {};
      filter.type = filterValues?.type ? filtersList?.typePackage.filter((s: { value: string }) => s.value === filterValues.type)[0] : {};

      sessionStorage.setItem('@App:filters', JSON.stringify(filter));

      getPlans();
    }
  }

  return (
    <>
      <Container>
        <LeftContent>
          <ReservationDetailsHeader>
            <Link to="/">
              <RiArrowLeftSLine />
              Voltar para lista
            </Link>

            {storagedFilters?.date ? (
              <AvailableDate>
                <small>Disponível no dia</small>
                <b>
                  {`${new Date(selectedDate.setHours(selectedDate.getHours() + 3))?.getDate()} de ${getMonthName(selectedDate)} de ${selectedDate?.getFullYear()}`}
                </b>
              </AvailableDate>
            ) : ''}
            <button type="button" onClick={() => scrollToRef('plansSection')}>
              <RiMagicFill />
              Realizar minha pré-reserva!
            </button>
          </ReservationDetailsHeader>

          <AboutPartyHouse>
            <AboutPartyHousePhotos>
              <MainPhoto
                src={partyHouseInfos.principalPhotoUrl}
                alt={
                  partyHouseInfos?.attributes?.cover?.data?.attributes
                    ?.alternativeText || ''
                }
              />
              <PhotosGrid>
                <PhotoGridRow>
                  {partyHouseInfos?.attributes?.media?.data !== null ? (
                    partyHouseInfos?.attributes?.media.data.slice(0, 2).map((item: any) => (
                      <PhotoGridItem
                        key={item?.id}
                        // src={`${baseURL}${item?.attributes?.url}`}
                        alt={item?.attributes?.alternativeText}
                      />
                    ))
                  ) : (
                    <PhotoGridItem src={EmptyHouse} />
                  )}
                </PhotoGridRow>
              </PhotosGrid>
            </AboutPartyHousePhotos>

            <AboutPartyHouseHeader>
              <AboutPartyHouseTitles>
                <h1>{partyHouseInfos?.name || ''}</h1>
                <small>
                  <b>
                    {partyHouseInfos?.address ? `${partyHouseInfos?.address?.street}, ${partyHouseInfos?.address?.number}, ${partyHouseInfos?.address?.neighborhood} - ${partyHouseInfos?.address?.city?.name}` : ''}
                  </b>
                </small>
              </AboutPartyHouseTitles>
              <PartyHouseRating>
                <RatingStars>
                  {partyHouseInfos?.rating?.average || '--'}
                  {[...Array(Math.trunc(partyHouseInfos?.rating?.average) || 0)].map(() => <RiStarFill key={uniqueId()} />)}
                </RatingStars>
                <small>
                  {partyHouseInfos?.rating?.total || '0'}
                  {' '}
                  Avaliações
                </small>
              </PartyHouseRating>
            </AboutPartyHouseHeader>

            <PartyHouseCapacity>
              <PartyHouseCapacityItem>
                <RiGroupLine />
                <PartyHouseCapacityTexts>
                  <small>Capacidade</small>
                  <b>
                    {partyHouseInfos?.capacity || '0'}
                    {' '}
                    Pessoas
                  </b>
                </PartyHouseCapacityTexts>
              </PartyHouseCapacityItem>

              <PartyHouseCapacityItem>
                <RiUserSmileLine />
                <PartyHouseCapacityTexts>
                  <small>Crianças</small>
                  <b>
                    {partyHouseInfos?.children || '0'}
                    {' '}
                    crianças
                  </b>
                </PartyHouseCapacityTexts>
              </PartyHouseCapacityItem>

              <PartyHouseCapacityItem>
                <RiCarLine />
                <PartyHouseCapacityTexts>
                  <small>Estacionamento</small>
                  <b>
                    {partyHouseInfos?.parkingSpace
                      || '0'}
                    {' '}
                    vagas
                  </b>
                </PartyHouseCapacityTexts>
              </PartyHouseCapacityItem>

              <PartyHouseCapacityItem>
                <RiMergeCellsHorizontal />
                <PartyHouseCapacityTexts>
                  <small>Área total</small>
                  <b>
                    {partyHouseInfos?.totalArea || '0'}
                    {' '}
                    m²
                  </b>
                </PartyHouseCapacityTexts>
              </PartyHouseCapacityItem>
            </PartyHouseCapacity>

            <ButtonPreBooking>
              <button type="button">
                <RiMagicFill />
                Realizar minha pré-reserva!
              </button>
            </ButtonPreBooking>

            <PartyHouseDescription>
              {partyHouseInfos?.description || ''}
            </PartyHouseDescription>

            {facilities.length > 0 && (
              <PartyHouseFacilities>
                <h3>O que a casa oferece</h3>
                <FacilitiesList>
                  {facilities
                    .slice(0, 6)
                    .map((facility: { id: string; icon: string; name: string; }) => (
                      <FacilitiesItem key={facility.id}>
                        {getFacilityIcon(facility.icon)}
                        {' '}
                        {facility.name}
                      </FacilitiesItem>
                    ))}
                </FacilitiesList>

                <button
                  type="button"
                  onClick={() => setVisibleAllItemsModal(true)}
                  disabled={!facilities.length}
                >
                  Mostrar todos os itens
                </button>
              </PartyHouseFacilities>
            )}
          </AboutPartyHouse>

          <PricesAndConditions id="plansSection">
            <h2>Pacotes e preços</h2>
            {/* <p>
              Nos diga algumas informações sobre sua festa para as melhores
              ofertas da
              {' '}
              <b>{partyHouseInfos?.attributes?.name || ''}</b>
            </p> */}

            <PricesAndConditionsFilters>
              <PriceAndConditionFilter>
                <small>Tipo de festa</small>
                <select onChange={(e) => handleInputChange(e)} name="type" value={filterValues.type}>
                  <option>Selecione</option>
                  {filtersList?.typePackage?.map((type: any) => (
                    <option value={type?.value} key={type?.value}>{type?.name}</option>
                  ))}
                  ;
                </select>
              </PriceAndConditionFilter>
              <PriceAndConditionFilter>
                <small>Data da festa</small>
                <input
                  name="date"
                  type="date"
                  placeholder="Data da festa"
                  onChange={(e) => handleInputChange(e)}
                  defaultValue={filterValues?.date}
                />
              </PriceAndConditionFilter>
              <PriceAndConditionFilter>
                <small>Nº de convidados</small>
                <select onChange={(e) => handleInputChange(e)} name="guests" value={filterValues.guests}>
                  <option>Selecione</option>
                  {filtersList?.capacity?.map((capacity: any) => (
                    <option value={capacity} key={capacity}>{capacity}</option>
                  ))}
                  ;
                </select>
              </PriceAndConditionFilter>
              <PriceAndConditionFilter>
                <small>Turno</small>
                <select onChange={(e) => handleInputChange(e)} name="shift" value={filterValues.shift}>
                  <option>Selecione</option>
                  {filtersList?.shifts?.map((shift: any) => (
                    <option value={shift?.value} key={shift?.value}>{shift?.name}</option>
                  ))}
                  ;
                </select>
              </PriceAndConditionFilter>
              <button type="button" onClick={handleFilterChange}>
                <RiSearchLine />
              </button>
            </PricesAndConditionsFilters>

            {Object.keys(storagedFilters)?.length !== 0 && (
              <p>
                Exibindo planos e condições para uma festa no dia
                {' '}
                <b>
                  {`${new Date(selectedDate.setHours(selectedDate.getHours() + 3))?.getDate()} de ${getMonthName(selectedDate)} de ${selectedDate?.getFullYear()}` ?? ''}
                  , comportando
                  {' '}
                  {storagedFilters?.guests ?? ''}
                  {' '}
                  convidados:
                </b>
              </p>
            )}

            <PackagesList>
              {plansList?.length ? plansList?.map(
                (packageItem: any) => {
                  const planDetails = { ...packageItem };

                  return (
                    <PackageItem
                      onClick={() => setActivePackage(`${packageItem?.idPrice}`)}
                      active={activePackage === `${packageItem?.idPrice}`}
                      key={`${packageItem?.idPrice}`}
                    >
                      <PackageDescriptionContent>
                        <PackageItemRadio>
                          <span />
                        </PackageItemRadio>
                        <PackageItemTexts>
                          <h4>{planDetails?.name || ''}</h4>
                          <p>{planDetails?.description || ''}</p>
                        </PackageItemTexts>
                      </PackageDescriptionContent>
                      <PackageItemContent>
                        <PackageItemPrice>
                          <h1>
                            para
                            {' '}
                            {planDetails?.guests || ''}
                            {' '}
                            convidados
                          </h1>
                        </PackageItemPrice>
                        <PackageItemPrice>
                          <b>{formatNumberToPrice(planDetails?.price) || '00'}</b>
                          {/* <small>12x de R$0,00</small> */}
                        </PackageItemPrice>
                        <button
                          type="button"
                          onClick={() => setSelectedPlanValue(planDetails?.price)}
                        >
                          Saiba mais
                        </button>
                      </PackageItemContent>
                    </PackageItem>
                  );
                },
              ) : (
                <EmptyBox>
                  <p>Nenhum plano disponível para esta casa de festas na data selecionada.</p>
                </EmptyBox>
              )}
            </PackagesList>

            <PreBookingWarning>
              <p>
                {partyHouseInfos?.attributes?.infoBooking
                  || 'Ao realizar sua pré-reserva, você terá até 3 dias úteis para realizar a entrada e confirmar sua reserva de forma definitiva.'}
              </p>

              <PreBookingButton>
                {!activePackage && (
                  <WarningTooltip>
                    <RiInformationLine />
                    Selecione um plano para realizar uma pré-reserva!
                  </WarningTooltip>
                )}
                <button
                  type="button"
                  disabled={!activePackage}
                  onClick={() => setVisiblePreBookingModal(true)}
                >
                  <RiMagicFill />
                  Realizar minha pré-reserva!
                </button>
              </PreBookingButton>
            </PreBookingWarning>

            {/* <PaymentConditions>
              <h2>Condições de pagamento</h2>

              <PaymentSectionBox>
                <small>Meios de pagamento que a casa aceita:</small>
                <PaymentMethodsRow>
                  <PaymentIconBox>
                    <img src={Pix} alt="Pix" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={Boleto} alt="Boleto" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={MasterCard} alt="MasterCard" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={Visa} alt="Visa" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={Elo} alt="Elo" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={Amex} alt="Amex" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={DinersClub} alt="DinersClub" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={PayPal} alt="PayPal" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={BanriCompras} alt="BanriCompras" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={PicPay} alt="PicPay" />
                  </PaymentIconBox>
                </PaymentMethodsRow>
              </PaymentSectionBox>
            </PaymentConditions> */}
          </PricesAndConditions>
        </LeftContent>

        {selectedPlanValue && (
          <PlanModal
            handleClose={() => setSelectedPlanValue('')}
            toggleAllItemsModal={() => setVisibleAllItemsModal(!visibleAllItemsModal)}
            toggleMenuItemsModal={() => setVisibleMenuItemsModal(!visibleMenuItemsModal)}
            togglePreBookingModal={() => setVisiblePreBookingModal(!visiblePreBookingModal)}
            selectedPackage={activePackage}
            selectedPlanValue={formatNumberToPrice(selectedPlanValue) || '00'}
            facilities={facilities}
            houseInfos={partyHouseInfos}
          />
        )}

        {visibleAllItemsModal && (
          <AllItemsModal
            handleClose={() => setVisibleAllItemsModal(false)}
            facilities={facilities}
            toys={partyHouseInfos?.attributes?.toys?.data || []}
            activities={partyHouseInfos?.attributes?.activities?.data || []}
          />
        )}

        {visibleMenuItemsModal && (
          <MenuItemsModal
            handleClose={() => setVisibleMenuItemsModal(false)}
            selectedPackage={activePackage}
          />
        )}

        {visiblePreBookingModal && (
          <PreBookingModal
            handleClose={() => setVisiblePreBookingModal(false)}
            handlePreBookingConfirmation={() => setVisiblePreBookingConfirmationModal(true)}
            selectedPackage={activePackage}
            selectedPlanValue={formatNumberToPrice(selectedPlanValue) || '00'}
            houseInfos={partyHouseInfos}
          />
        )}

        {visiblePreBookingConfirmationModal && (
          <PreBookingConfirmationModal
            handleClose={() => setVisiblePreBookingConfirmationModal(false)}
            selectedPackage={activePackage}
            houseInfos={partyHouseInfos}
          />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default HouseDetail;
