import styled, { css } from 'styled-components';

interface PackageItemProps {
  active?: boolean;
}

export const Container = styled.div`
  margin-top: 80px;
  position: relative;

  display: flex;

  @media (max-width: 700px) {
    margin-top: 63px;
  }
`;

export const LeftContent = styled.div`
width: 100%;
`;

export const RightMap = styled.div`
  max-width: 355px;
  width: 100%;
  min-height: 100%;

  @media (max-width: 1340px) {
    display: none;
  }

  @media (min-width: 1568px) {
    max-width: 840px;
  }
`;

export const ReservationDetailsHeader = styled.div`
  ${({ theme }) => css`
    padding: 17px 32px;
    background-color: rgba(64, 78, 237, 0.9);

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 700px) {
      padding: 12px 32px;
    }

    > button {
      background-color: ${theme.colors.alternativeHot01};
      border-radius: 10px;
      padding: 16px 32px;
      height: 50px;
      color: ${theme.colors.neutral11};
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 1.6rem;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: ${theme.transitions.default};

      @media (max-width: 700px) {
        display: none;
      }

      &:hover {
        opacity: 0.8;
      }

      > svg {
        margin-right: 10px;
        font-size: 1.8rem;
      }
    }

    > a {
      color: ${theme.colors.neutral11};
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;

      display: flex;
      align-items: center;

      @media (max-width: 700px) {
        font-size: 1.2rem;
      }

      > svg {
        margin-right: 4px;
        font-size: 2rem;
      }
    }
  `};
`;

export const AvailableDate = styled.div`
  ${({ theme }) => css`
    margin-left: auto;
    margin-right: 28px;
    color: ${theme.colors.neutral11};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 700px) {
      font-size: 1.2rem;
    }
  `};
`;

export const AboutPartyHouse = styled.div`
  padding: 32px;
`;

export const AboutPartyHouseHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid ${theme.colors.neutral10};
    padding-bottom: 44px;

    @media (max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
    }
  `};
`;

export const AboutPartyHouseTitles = styled.div`
  ${({ theme }) => css`
    > h1 {
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.2rem;
      color: ${theme.colors.neutral80};
      margin-bottom: 8px;
    }

    > small {
      font-size: 1.8rem;
      line-height: 1.8rem;
      color: ${theme.colors.neutral80};

      > b {
        margin-right: 4px;
      }
    }

    @media (max-width: 700px) {
      > h1 {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }

      > small {
        font-size: 1.4rem;
      }
    }
  `};
`;

export const PartyHouseRating = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 700px) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    > small {
      color: ${theme.colors.neutral30};
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-top: 14px;
    }
  `};
`;

export const RatingStars = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    font-size: 2.4rem;
    font-weight: bold;

    > span {
      color: ${theme.colors.neutral80};
      font-weight: 700;
      line-height: 1.8rem;
      margin-right: 10px;
    }

    > svg {
      color: ${theme.colors.alternativeHot05};
    }
  `};
`;

export const PartyHouseCapacity = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid ${theme.colors.neutral10};
    padding: 24px 0;
    @media (max-width: 700px) {
      flex-wrap: wrap;
      padding: 18px 0;
    }
  `};
`;

export const PartyHouseCapacityItem = styled.div`
  ${({ theme }) => css`
    padding: 0 12px;
    max-width: 200px;
    width: 100%;

    display: flex;
    align-items: center;

    @media (max-width: 700px) {
      padding: 12px 0;
      max-width: 160px;
    }

    > svg {
      font-size: 2.4rem;
      margin-right: 16px;
      color: ${theme.colors.alternativeCold01};

      @media (max-width: 700px) {
        font-size: 2rem;
      }
    }
  `};
`;

export const PartyHouseCapacityTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.neutral30};
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 6px;

      @media (max-width: 700px) {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }

    > b {
      color: ${theme.colors.neutral80};
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.8rem;

      @media (max-width: 700px) {
        font-size: 1.6rem;
        line-height: 1.6rem;
      }
    }
  `};
`;

export const PartyHouseDescription = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral80};
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin: 44px 0;

    @media (max-width: 700px) {
      font-size: 1.4rem;
      line-height: 2rem;
      margin: 18px 0 30px;
    }
  `};
`;

export const PricesAndConditions = styled.div`
  ${({ theme }) => css`
    padding: 54px;
    background-color: rgba(64, 78, 237, 0.05);

    @media (max-width: 700px) {
      padding: 32px;
    }

    > h2 {
      color: ${theme.colors.neutral80};
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
      margin-bottom: 12px;

      @media (max-width: 700px) {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }

    > p {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: ${theme.colors.neutral80};

      @media (max-width: 700px) {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  `};
`;

export const PreBookingButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    @media (max-width: 700px) {
      flex-direction: column-reverse;
      padding: 0;
      background-color: transparent;
    }

    > p {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
      color: ${theme.colors.alternativeCold01};
      max-width: 484px;
      width: 100%;

      @media (max-width: 700px) {
        margin-top: 24px;
        text-align: center;
      }
    }

    > button {
      background-color: ${theme.colors.alternativeCold01};
      border-radius: 10px;
      padding: 32px 48px;
      height: 82px;
      margin-left: 44px;
      font-size: 1.8rem;
      line-height: 1.8rem;
      font-weight: 700;
      color: ${theme.colors.neutral11};
      transition: ${theme.transitions.default};

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      > svg {
        margin-right: 10px;
      }
    }
  `};
`;

export const ButtonPreBooking = styled.div`
  ${({ theme }) => css`
    @media (min-width: 411px) {
      display: none;
    }

    > button {
      border-radius: 10px;
      background-color: ${theme.colors.alternativeCold01};
      height: 82px;
      padding: 32px 48px;
      max-width: 355px;
      width: 100%;
      margin-left: 44px;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.8rem;
      color: ${theme.colors.neutral11};

      display: flex;
      align-items: center;

      transition: ${theme.transitions.default};

      @media (max-width: 700px) {
        margin-left: 0;
        padding: 24px 18.5px;
        height: 66px;
        max-width: 350px;
      }

      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      > svg {
        margin-right: 10px;
        font-size: 1.8rem;
      }
    }
  `};
`;

export const WarningTooltip = styled.span`
  ${({ theme }) => css`
    font-size: 1.2rem;
    line-height: 1.5rem;
    background-color: ${theme.colors.neutral10};
    padding: 10px;
    height: 45px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -52px;
    left: 55%;
    transform: translateX(-50%);
    width: 100%;
    
    > svg {
      margin-right: 8px;
    }
  `};
`;

export const PreBookingWarning = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: rgba(33, 83, 214, 0.05);
    border-radius: 10px;
    padding: 32px;
    max-height: 146px;
    margin-top: 54px;
   
    > p {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
      color: ${theme.colors.alternativeCold01};
      max-width: 484px;
      width: 100%;
    }
  `};
`;

export const PartyHouseFacilities = styled.div`
  ${({ theme }) => css`
    > h3 {
      color: ${theme.colors.neutral80};
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
    }

    > button {
      border: 1px solid rgba(64, 78, 237, 0.2);
      border-radius: 10px;
      background-color: transparent;
      height: 58px;
      max-width: 295px;
      width: 100%;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.8rem;
      color: ${theme.colors.alternativeCold01};

      transition: ${theme.transitions.default};

      @media (max-width: 700px) {
        max-width: 100%;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;

export const FacilitiesItemsRow = styled.ul`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-right: 68px;
  }
`;

export const FacilitiesList = styled.div`
  margin: 24px 0;

  display: flex;
  flex-wrap: wrap;

  @media (max-width: 700px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export const FacilitiesItem = styled.li`
  ${({ theme }) => css`
    background: rgba(64, 78, 237, 0.05);
    border-radius: 50px;
    padding: 10px 20px;
    margin: 0 10px 0 0;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${theme.colors.alternativeCold01};

    display: flex;
    align-self: start;
    align-items: center;

    @media (max-width: 700px) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    :not(:last-child) {
      margin-bottom: 16px;
    }

    > svg {
      margin-right: 10px;
    }
  `};
`;

export const AboutPartyHousePhotos = styled.div`
  ${({ theme }) => css`
    margin-bottom: 28px;
    width: 100%;
    position: relative;

    display: flex;
    justify-content: space-between;
    /* align-items: center; */

    @media (max-width: 700px) {
      flex-direction: column;
    }

    > button {
      color: ${theme.colors.neutral80};
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.6rem;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      background-color: ${theme.colors.neutral11};
      height: 44px;
      max-width: 186.75px;
      width: 100%;

      position: absolute;
      bottom: 20px;
      right: 16px;

      display: flex;
      align-items: center;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }

      > b {
        margin: 0 4px;
      }
    }
  `};
`;

export const MainPhoto = styled.img`
  height: 310px;
  width: 100%;
  border-radius: 10px;
  margin-right: 24px;
  object-fit: cover;
  object-position: center;

  @media (max-width: 700px) {
    height: 250px;
    margin-right: 0;
  }
`;

export const PhotoGridRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 700px) {
    margin-top: 14px;
  }
`;

export const PhotosGrid = styled.div``;

export const PhotoGridItem = styled.img`
  height: 143px;
  width: 220px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;

  &:first-child {
    margin-bottom: 24px;
  }

  @media (max-width: 700px) {
    width: 100px;
    height: 85px;
    margin-right: 14px;

    &:first-child {
      margin-bottom: 14px;
    }
  }
`;

export const EmptyBox = styled.div`
  ${({ theme }) => css`
    padding: 32px;
    border-radius: 10px;
    min-height: 120px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      color: ${theme.colors.neutral80};
      font-size: 1.8rem;
      line-height: 2.4rem;
      font-weight: 400;
    }
  `};
`;

export const PackagesList = styled.div`
  margin-top: 44px;
`;

export const PackageItem = styled.div<PackageItemProps>`
  ${({ theme, active }) => css`
    background-color: ${theme.colors.neutral11};
    border-radius: 10px;
    padding: 18px 14px 18px 32px;
    cursor: pointer;
    border: 2px solid ${theme.colors.neutral11};

    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: ${theme.transitions.default};

    @media (max-width: 700px) {
      flex-wrap: wrap;
      padding: 18px;
    }

    :not(:last-child) {
      margin-bottom: 18px;
    }

    > button {
      background-color: ${theme.colors.alternativeCold01};
      border-radius: 0 10px 10px 0;
      height: 98px;
      max-width: 170px;
      width: 100%;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.8rem;
      color: ${theme.colors.neutral11};

      transition: ${theme.transitions.default};

      @media (max-width: 700px) {
        max-width: 50%;
        height: 68px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    ${active
    && css`
      border: 2px solid ${theme.colors.alternativeHot01};

      ${PackageItemRadio} {
        > span {
          background-color: ${theme.colors.alternativeHot01};
        }
      }
    `};
  `};
`;

export const PackageItemTexts = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral80};
    margin-right: 14px;

    @media (max-width: 700px) {
      margin: 18px 0;
    }

    > h4 {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 6px;

      display: flex;
      align-items: center;

      @media (max-width: 700px) {
        font-size: 1.8rem;
        line-height: 1.8rem;
        margin-bottom: 4px;
      }
    }

    > p {
      color: ${theme.colors.neutral80};
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  `};
`;

export const PackageItemContent = styled.div<PackageItemProps>`
  display: flex;
  width: 40%;

  @media (max-width: 700px) {
    width: 100%;
  }
  ${({ theme, active }) => css`
    > button {
      background-color: ${theme.colors.alternativeCold01};
      border-radius: 0 10px 10px 0;
      height: 98px;
      max-width: 170px;
      width: 100%;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.8rem;
      color: ${theme.colors.neutral11};

      transition: ${theme.transitions.default};

      @media (max-width: 700px) {
        max-width: 50%;
        height: 68px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    ${active
    && css`
      border: 2px solid ${theme.colors.alternativeHot01};

      ${PackageItemRadio} {
        > span {
          background-color: ${theme.colors.alternativeHot01};
        }
      }
    `};
  `};
`;

export const PackageItemPrice = styled.div`
  ${({ theme }) => css`
    margin-left: 10px;
    background: rgba(236, 107, 30, 0.05);
    border-radius: 10px 0px 0px 10px;
    height: 98px;
    max-width: 178px;
    width: 100%;
    padding: 22px 24px;
    color: ${theme.colors.alternativeHot01};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 700px) {
      padding: 14px 18px;
      max-width: 50%;
      height: 68px;
      margin-left: 0;
    }

    > b {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 6px;

      @media (max-width: 700px) {
        font-size: 1.8rem;
        line-height: 1.8rem;
      }
    }

    > small {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;

      @media (max-width: 700px) {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
  `};
`;

export const OfferTag = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.neutral11};
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.8rem;
    background-color: ${theme.colors.alternativeCold08};
    border-radius: 50px;
    padding: 4px 8px;
    width: 97px;
    height: 26px;
    margin-left: 12px;
  `};
`;

export const PackageDescriptionContent = styled.span`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 50%;
  `};
`;

export const PricesAndConditionsFilters = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin: 54px 0;

    @media (max-width: 1340px) {
      flex-wrap: wrap;
    }

    > button {
      width: 68px;
      height: 68px;
      background-color: ${theme.colors.alternativeHot01};
      border-radius: 100px;
      color: ${theme.colors.neutral11};
      font-size: 1.8rem;

      transition: ${theme.transitions.default};

      @media (max-width: 1340px) {
        width: 100%;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;

export const PriceAndConditionFilter = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral11};
    height: 68px;
    flex: 1;
    border-radius: 100px;
    padding: 15px 24px;
    color: ${theme.colors.alternativeCold01};
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;

    > select {
      color: ${theme.colors.alternativeCold01};
      /* background-color: red; */
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      border: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    > input {
      color: ${theme.colors.alternativeCold01};
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      border: 0;

      &::placeholder {
        color: ${theme.colors.alternativeCold01};
      }
    }

    > small {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    :not(:last-child) {
      margin-right: 12px;
    }

    @media (max-width: 1340px) {
      margin-bottom: 12px;

      width: 100%;
      flex: none;

      :not(:last-child) {
        margin-right: 0px;
      }
    }
  `};
`;

export const PackageItemRadio = styled.div`
  ${({ theme }) => css`
    margin-right: 30px;
    min-width: 56px;
    min-height: 56px;
    border: 3px solid ${theme.colors.alternativeHot01};
    border-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 700px) {
      margin-right: 90%;
      min-width: 38px;
      min-height: 38px;
    }

    > span {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: ${theme.colors.neutral11};

      transition: ${theme.transitions.default};

      @media (max-width: 700px) {
        width: 24px;
        height: 24px;
      }
    }
  `};
`;

export const PaymentConditions = styled.div`
  ${({ theme }) => css`
    border: 1px solid rgba(64, 78, 237, 0.2);
    padding: 32px;
    border-radius: 10px;
    margin-top: 54px;

    @media (max-width: 700px) {
      border: none;
      padding: 0;
    }

    > h2 {
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
      margin-bottom: 24px;
      color: ${theme.colors.neutral80};

      @media (max-width: 700px) {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }
  `};
`;

export const PaymentSectionBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    padding-bottom: 24px;

    @media (max-width: 700px) {
      padding-bottom: 0;
    }

    > small {
      color: ${theme.colors.alternativeCold01};
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 1.6rem;
      margin-bottom: 24px;
    }

    > p {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: ${theme.colors.neutral80};
    }

    :not(:last-child) {
      border-bottom: 1px solid ${theme.colors.neutral10};
      margin-bottom: 24px;
    }
  `};
`;

export const PaymentMethodsRow = styled.div`
  display: flex;

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`;

export const PaymentIconBox = styled.div`
  ${({ theme }) => css`
    width: 50px;
    height: 35px;
    background-color: ${theme.colors.neutral11};
    border-radius: 4px;
    border: 0.721535px solid #d9d9d9;
    padding: 10px 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    :not(:last-child) {
      margin-right: 9px;
    }

    @media (max-width: 700px) {
      margin-bottom: 12px;

      :not(:last-child) {
        margin-right: 10.18px;
      }
    }
  `};
`;

export const CustomMarker = styled.div`
  position: relative;
`;

export const Marker = styled.img`
  /* width: 50.47px; */
  height: 89.16px;

  position: relative;
`;

export const MoreInfosTooltip = styled.div`
  width: 300px;

  transform: translateX(-40%) translateY(12px);

  background: #ffffff;
  display: block;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  color: #ee3d53;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  padding: 24px;
  font-family: 'Cera Round';

  display: flex;
  flex-direction: column;

  > small {
    color: #333333;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-top: 12px;
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin-top: 5px;
  }

  > button {
    margin-top: 10px;
    background-color: transparent;
    color: #ee3d53;
  }
`;
