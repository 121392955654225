import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

const FormErrorNotification = (): null => {
  const { isValid, isValidating, isSubmitting } = useFormikContext();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      toast.error(
        'Para encontrarmos o melhor local para sua festa precisamos de todos os campos preenchidos.',
        {
          duration: 4000,
          position: 'bottom-center',
          style: {
            backgroundColor: '#c2ae00',
            color: '#000',
            fontSize: 16,
          },
          icon: '⚠️',
        },
      );
    }
  }, [isSubmitting, isValid, isValidating]);

  return null;
};

export default FormErrorNotification;
