import qs from 'qs';
import { House } from '../types/house';
import { Entity } from '../types/strapi';
import api from '../clients/api.client';
import { HouseFilterCombo, HouseSearchFilter } from '../types/house.search.filter';

export default {
  async get(filter: HouseSearchFilter = {}): Promise<Array<Entity<House>>> {
    try {
      delete api.defaults.headers.common.Authorization;

      return (
        await api.get('/house', {
          paramsSerializer: (params) => qs.stringify(params),
          params: {
            city: filter?.city,
            type: filter?.type ? (filter?.type as unknown as HouseFilterCombo).value : undefined,
            date: filter?.date,
            guests: filter?.guests,
            shift: filter?.shift ? (filter?.shift as unknown as HouseFilterCombo).value : undefined,
          },
        })
      ).data;
    } catch {
      throw new Error('Unable to list party houses!');
    }
  },

  async getOne(id: string | number): Promise<Entity<House>> {
    try {
      return (
        await api.get(`/house/${id}`, {})
      ).data?.data;
    } catch {
      throw new Error('Unable to list a party house!');
    }
  },

  async getBySlug(slug: string): Promise<Entity<House>> {
    try {
      return (
        await api.get(`/house/slug/${slug}`, {})
      ).data;
    } catch {
      throw new Error('Unable to list a party house!');
    }
  },
};
