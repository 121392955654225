import styled, { css } from 'styled-components';

export const PartyHouseCardImage = styled.img`
  height: 230px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;

  margin-right: 0;
  max-width: unset;
  margin-bottom: 23px;
`;

export const PartyHouseCard = styled.div<{ visible?: any }>`
  width: 100%;
  max-width: 315px;
  margin-bottom: 24px;
  margin-right: 24px;
  cursor: pointer;

  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    max-width: unset;
    margin-right: unset;
  }

  ${({ visible }) => css`
    ${visible
    && css`
      flex-direction: row;
      max-width: 100%;
      margin-right: unset;

      ${PartyHouseCardImage} {
        margin-right: 30px;
        max-width: 275px;
        height: 200px;
        margin-bottom: unset;
      }

      ${PartyHouseCardFooter} {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }

      ${PartyHouseCardPrice} {
        flex-direction: column;
        align-items: unset;
        margin-top: unset;

        > b { 
          margin-top: 6px;
        }
      }
    `};
  `};
`;

export const PartyHouseCardInfos = styled.div`
  ${({ theme }) => css`
    > h3 {
      color: ${theme.colors.neutral00};
      font-size: 1.8rem;
      line-height: 1.8rem;
      font-weight: 700;
      margin-bottom: 6px;
    }

    > small {
      color: ${theme.colors.neutral30};
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    > p {
      color: ${theme.colors.neutral50};
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 400;
      margin: 24px 0;
      display: block; 
      display: -webkit-box;

      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `};
`;

export const PartyHouseCardFooter = styled.div`
  display: flex;
  flex-direction: column;

`;

export const PartyHouseRating = styled.div`
  ${({ theme }) => css`
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    color: ${theme.colors.neutral80};

    display: flex;
    align-items: center;

    > svg {
      color: ${theme.colors.alternativeHot05};
      margin-right: 8px;
    }

    > span {
      margin-left: 6px;
      color: ${theme.colors.neutral50};
      font-weight: 400;
    }
  `};
`;

export const PartyHouseCardPrice = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral50};
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 24px;
  
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > button {
      background-color: transparent;
      border: 1px solid ${theme.colors.alternativeCold01};
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-weight: 400;
      border-radius: 4px;
      padding: 10px;
      color: ${theme.colors.alternativeCold01};

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }

    > b {
      color: ${theme.colors.alternativeCold01};
      font-size: 1.6rem;
      /* margin-top: 6px;  */
      font-weight: 700;
      margin-left: 8px;
      
      > em {
        font-style: normal;
        font-size: 3rem;
        margin: 0 2px;
      }
    }
  `};
`;
