import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { RiCloseLine, RiShieldCheckFill, RiInformationLine } from 'react-icons/ri';
import api from '../../clients/api.client';

import {
  Container,
  ModalContent,
  ModalContentHeader,
  PartnerFormBox,
  InputControl,
  Toast,
  ModalSubHeader,
} from './styles';

interface PartnerFormProps {
  handleToggleModal: () => void;
}

interface FormInputValuesProps {
  houseName: string;
  houseCity: string;
  houseSocial: string;
  housePhone: string;
  houseEmail: string;
  fullName: string;
  formName: string;
}

const PartnerForm = ({ handleToggleModal }: PartnerFormProps): ReactElement => {
  const [formInputValues, setFormInputValues] = useState({} as FormInputValuesProps);
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);
  const [toastType, setToastType] = useState<string>('error');

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  function handleInputChange(event: any): void {
    const { name, value } = event?.target;

    setFormInputValues({
      ...formInputValues,
      [name]: value,
    });
  }

  async function handleLeadCreation(event: FormEvent): Promise<void> {
    event.preventDefault();

    try {
      formInputValues.formName = 'lead-be-partner';
      await api.post('/form', formInputValues);

      toast.success(
        'Ficamos felizes pelo seu interesse! Já vamos entrar em contato com você.',
        {
          duration: 4000,
          position: 'top-center',
          style: {
            backgroundColor: '#00a314',
            color: '#fff',
            fontSize: 16,
          },
          icon: '✅',
        },
      );
      handleToggleModal();
    } catch {
      setToastType('error');
      setIsVisibleToast(true);
    }

    setTimeout(async () => {
      setIsVisibleToast(false);
    }, 4500);
  }

  return (
    <Container>
      <ModalContent>
        <ModalContentHeader>
          <h2>Seja um parceiro!</h2>
          <RiCloseLine onClick={handleToggleModal} />
        </ModalContentHeader>
        <ModalSubHeader>
          Quer aumentar a locação do seu espaço de festas? Então venha fazer parte da NIVIS
        </ModalSubHeader>

        <PartnerFormBox onSubmit={(e) => handleLeadCreation(e)}>
          <InputControl>
            <small>Nome da casa de festa</small>
            <input type="text" name="houseName" placeholder="Ex: Casa de Festa Sonho Feliz" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>Cidade</small>
            <input type="text" name="houseCity" placeholder="Ex: Porto Alegre" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>Contato</small>
            <input type="text" name="houseSocial" placeholder="Telefone, e-mail ou rede social" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>Telefone (WhastApp)</small>
            <input type="text" name="housePhone" placeholder="Ex: 51 9 9999 9999" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>E-mail para contato</small>
            <input type="email" name="houseEmail" placeholder="Ex: casadefestasonhofeliz@gmail.com" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>Responsável</small>
            <input type="text" name="fullName" placeholder="Ex: Carlos Garcia" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <button type="submit">QUERO ESTAR NA NIVIS</button>
          <small>
            <RiShieldCheckFill />
            Seus dados seguros conforme nossas políticas de privacidade.
          </small>
        </PartnerFormBox>

        <Toast isVisible={isVisibleToast} toastType={toastType}>
          <RiInformationLine />
          {toastType === 'success' ? 'Ficamos felizes pelo seu interesse! Já vamos entrar em contato com você.' : 'Erro ao enviar solicitação, tente novamente.'}
        </Toast>
      </ModalContent>
    </Container>
  );
};

export default PartnerForm;
