import api from '../clients/api.client';

export default {
  async get(): Promise<any> {
    try {
      return (await api.get('/filter/parameters')).data;
    } catch {
      throw new Error('Unable to list parameters for the filter');
    }
  },
};
