/* eslint-disable camelcase */
import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { RiCloseLine } from 'react-icons/ri';

import { plansService } from '../../services';

import {
  Container,
  AllItemsModalContent,
  ModalHeader,
  ModalBody,
  Content,
  EmptyMessage,
} from './styles';

interface MenuItemsModalProps {
  handleClose: () => void;
  selectedPackage: string | number;
}

const MenuItemsModal = ({
  handleClose,
  selectedPackage,
}: MenuItemsModalProps): ReactElement => {
  const [menuItems, setMenuItems] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getMenuInformations();
  }, []);

  async function getMenuInformations(): Promise<void> {
    setIsLoading(true);
    setMenuItems((await plansService.getMenuItens(selectedPackage)) ?? []);
    setIsLoading(false);
  }

  return (
    <Container>
      <AllItemsModalContent>
        <ModalHeader>
          <h2>Cardápio</h2>

          <RiCloseLine onClick={handleClose} />
        </ModalHeader>
        <ModalBody>
          {menuItems?.length && !isLoading ? menuItems.map(
            (menuItem: { id: string; icon: string; name: string; }) => (
              <Content key={menuItem.id}>
                <ul>
                  <li>{menuItem.name}</li>
                </ul>
              </Content>
            ),
          ) : isLoading && !menuItems?.length ? (
            <EmptyMessage>
              <h2>Carrengando cardápio da casa de festas..</h2>
            </EmptyMessage>
          ) : (
            <EmptyMessage>
              <h2>Nenhum cardápio cadastrado para o pacote selecionado.</h2>
            </EmptyMessage>
          )}
        </ModalBody>
      </AllItemsModalContent>
    </Container>
  );
};

export default MenuItemsModal;
