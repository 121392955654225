import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';

import { RiCloseLine, RiStarFill, RiMailCheckFill } from 'react-icons/ri';

import LargeLogo from '../../assets/LargeLogo.png';
import { plansService } from '../../services';

import {
  Container,
  PreBookingModalContent,
  ModalHeader,
  ModalBody,
  FinalValueInformations,
  ModalBodyMainTexts,
  ModalBodyRating,
  RatingStars,
  PlanInformations,
  PlanFilters,
  PlanFilterItem,
  ModalHeaderTop,
  ReservationNumber,
} from './styles';

interface PreBookingConfirmationModalProps {
  handleClose: () => void;
  selectedPackage: string | number;
  houseInfos: any;
}

const PreBookingConfirmationModal = ({
  handleClose,
  selectedPackage,
  houseInfos,
}: PreBookingConfirmationModalProps): ReactElement => {
  const [packageInformations, setPackageInformations] = useState<any>([]);
  const [storagedFilters, setStoragedFilters] = useState<any>({});

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    getPlanInformations();

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  async function getPlanInformations(): Promise<void> {
    try {
      const parsedFilters = JSON.parse(sessionStorage.getItem('@App:filters') as any);
      setStoragedFilters(parsedFilters || []);

      const packageInformationsResponse = (await plansService.getOne(selectedPackage))?.attributes;
      setPackageInformations(packageInformationsResponse);
    } catch {
      // console.log('Error when list a selected package informations.');
    }
  }

  return (
    <Container>
      <PreBookingModalContent>
        <ModalHeader>
          <ModalHeaderTop>
            <img src={LargeLogo} alt="LargeLogo" />
            <RiCloseLine onClick={handleClose} />
          </ModalHeaderTop>

          <RiMailCheckFill />
          <h2>Oba! Sua pré-reserva está em andamento!</h2>
          <p>
            Vamos confirmar a disponibilidade da sua reserva e enviamos a confirmação para o seu e-mail:
            <b>seuemail@nivis.com.br</b>
            {' '}
            <ReservationNumber>
              <b>O número da sua pré-reserva: 000000</b>
            </ReservationNumber>
          </p>
        </ModalHeader>
        <ModalBody>
          <ModalBodyMainTexts>
            <h3>
              {houseInfos?.name ?? ''}
              <small>
                <b>
                  {`${houseInfos?.address?.address || ''}, ${houseInfos?.address?.number || ''} - ${houseInfos?.address?.neighborhood || ''}`}
                </b>
                -
                {' '}
                {houseInfos?.address?.city}
              </small>
            </h3>

            <ModalBodyRating>
              <RatingStars>
                5,0
                <RiStarFill />
                <RiStarFill />
                <RiStarFill />
                <RiStarFill />
                <RiStarFill />
              </RatingStars>
              <small>0 Avaliações</small>
            </ModalBodyRating>
          </ModalBodyMainTexts>

          <PlanInformations>
            <h4>
              {packageInformations?.name ?? ''}
            </h4>
            <p>{packageInformations?.description ?? ''}</p>

            <PlanFilters>
              <PlanFilterItem>
                <small>Tipo de festa</small>
                {storagedFilters?.type ?? 'Família'}
              </PlanFilterItem>

              <PlanFilterItem>
                <small>Data da festa</small>
                {moment(storagedFilters?.date).format('DD/MM/YYYY') ?? ''}
              </PlanFilterItem>

              <PlanFilterItem>
                <small>Nº de convidados</small>
                {storagedFilters?.guests ?? '50'}
              </PlanFilterItem>

              <PlanFilterItem>
                <small>Turno</small>
                {storagedFilters?.shift ?? 'Tarde'}
              </PlanFilterItem>
            </PlanFilters>
          </PlanInformations>

          <FinalValueInformations>
            <h5>
              <small>Valor final:</small>
              R$0,00
            </h5>
            <p>
              {houseInfos?.infoBooking
                ?? 'Ao realizar sua pré-reserva, você terá até 3 dias úteis para realizar a entrada e confirmar sua reserva de forma definitiva.'}
            </p>
          </FinalValueInformations>
        </ModalBody>
      </PreBookingModalContent>
    </Container>
  );
};

export default PreBookingConfirmationModal;
