import api from '../clients/api.client';
import { Facility } from '../types/facility';
import { Entity } from '../types/strapi';

export default {
  async findByHouseId(houseId: string): Promise<Entity<Facility>> {
    try {
      return (
        await api.get(`/facilities/${houseId}`, {})
      ).data;
    } catch {
      throw new Error('Unable to load facilities');
    }
  },
};
