import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { RiCloseLine, RiShieldCheckFill, RiInformationLine } from 'react-icons/ri';
import api from '../../clients/api.client';

import {
  Container,
  ModalContent,
  ModalContentHeader,
  PartnerFormBox,
  InputControl,
  Toast,
  ModalSubHeader,
} from './styles';

interface IndicationFormModalProps {
  handleToggleModal: () => void;
}

interface FormInputValuesProps {
  houseName: string;
  houseCity: string;
  houseSocial: string;
  contactEmail: string;
  contactFullName: string;
  formName: string;
}

const IndicationFormModal = ({ handleToggleModal }: IndicationFormModalProps): ReactElement => {
  const [formInputValues, setFormInputValues] = useState({} as FormInputValuesProps);
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);
  const [toastType, setToastType] = useState<string>('error');

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  function handleInputChange(event: any): void {
    const { name, value } = event?.target;

    setFormInputValues({
      ...formInputValues,
      [name]: value,
    });
  }

  async function handleLeadCreation(event: FormEvent): Promise<void> {
    event.preventDefault();
    try {
      formInputValues.formName = 'lead-refer-partner';
      await api.post('/form', formInputValues);

      toast.success(
        'Valeu pela indicação! Nós entraremos em contato para adicioná-los na Plataforma.',
        {
          duration: 40000,
          position: 'bottom-center',
          style: {
            backgroundColor: '#00a314',
            color: '#fff',
            fontSize: 16,
          },
          icon: '✅',
        },
      );
      handleToggleModal();
    } catch {
      setToastType('error');
      setIsVisibleToast(true);
    }

    setTimeout(async () => {
      setIsVisibleToast(false);
    }, 4500);
  }

  return (
    <Container>
      <ModalContent>
        <ModalContentHeader>
          <h2>Indique uma casa de festa!</h2>
          <RiCloseLine onClick={handleToggleModal} />
        </ModalContentHeader>
        <ModalSubHeader>
          Estamos começando nossa operação e você pode nos ajudar indicando locais de festas bacana para fazer parte da plataforma.
        </ModalSubHeader>

        <PartnerFormBox onSubmit={(e) => handleLeadCreation(e)}>
          <InputControl>
            <small>Nome da casa de festa</small>
            <input
              type="text"
              name="houseName"
              placeholder="Ex: Casa de Festa Sonho Feliz"
              required
              onChange={(e) => handleInputChange(e)}
            />
          </InputControl>

          <InputControl>
            <small>Cidade</small>
            <input
              type="text"
              name="houseCity"
              placeholder="Ex: Porto Alegre"
              required
              onChange={(e) => handleInputChange(e)}
            />
          </InputControl>

          <InputControl>
            <small>Contato</small>
            <input type="text" name="houseSocial" placeholder="Telefone, e-mail ou rede social" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>Seu nome</small>
            <input type="text" name="contactFullName" placeholder="Maria Garcia" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>Seu e-mail</small>
            <input type="email" name="contactEmail" placeholder="Ex: mariagarcia@gmail.com" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <button type="submit">INDICAR LOCAL</button>
          <small>
            <RiShieldCheckFill />
            Dados seguros e fornecidos de forma anônima.
          </small>
        </PartnerFormBox>

        <Toast isVisible={isVisibleToast} toastType={toastType}>
          <RiInformationLine />
          {toastType === 'success' ? 'Valeu pela indicação! Nós entraremos em contato para adicioná-los na Plataforma.' : 'Não foi possível realizar a indicação'}
        </Toast>
      </ModalContent>
    </Container>
  );
};

export default IndicationFormModal;
